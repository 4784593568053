import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "z-10 flex h-full flex-col overflow-hidden" }
const _hoisted_2 = { class: "relative flex-1 overflow-y-auto" }

import { computed } from 'vue';

	// Hub imports
	import { useSettings } from '@/logic/store/settings';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderFooter',
  props: {
		bgBarLow: {
			type: String,
			default: 'bg-surface-low',
		},
		bgBarMedium: {
			type: String,
			default: 'bg-surface',
		},
	},
  setup(__props) {

	// Vue imports
	const settings = useSettings();
	const isMobile = computed(() => settings.isMobileState);

	const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['relative flex h-screen w-full flex-col justify-between overflow-hidden', __props.bgBarLow])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(['flex flex-col', __props.bgBarMedium, isMobile.value ? 'h-[7.5rem] justify-center p-3' : 'h-[10rem] justify-start p-4'])
      }, [
        _renderSlot(_ctx.$slots, "header")
      ], 2),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", { class: "p-3 md:p-4" })
      ])
    ]),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "footer")
    ])
  ], 2))
}
}

})