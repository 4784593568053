import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import HeaderFooter from '@/components/ui/HeaderFooter.vue';
	import H1 from '@/components/elements/H1.vue';
	import H2 from '@/components/elements/H2.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'PluginMenuExample',
  setup(__props) {

	// Components
	
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(HeaderFooter, { class: "pl-3" }, {
    header: _withCtx(() => [
      _createVNode(H1, { class: "text-blue m-0 font-bold" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('plugin-example.page.title')), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(H2, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('plugin-example.page.subtitle')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('plugin-example.page.text')), 1)
    ]),
    _: 1
  }))
}
}

})