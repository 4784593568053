import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "leading-4 ~text-label-min/label-max" }

import HeaderFooter from '@/components/ui/HeaderFooter.vue';
	import H1 from '@/components/elements/H1.vue';
	import Json from '@/components/elements/Json.vue';

	import { useRooms } from '@/logic/store/store';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'PluginRoomExample',
  setup(__props) {

	// Components
	const rooms = useRooms();

return (_ctx: any,_cache: any) => {
  return (_unref(rooms).currentRoomExists)
    ? (_openBlock(), _createBlock(HeaderFooter, {
        key: 0,
        class: "pl-3"
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createVNode(H1, { class: "text-blue m-0 font-bold" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(rooms).currentRoom?.name), 1)
                ]),
                _: 1
              }),
              _createElementVNode("p", _hoisted_2, "Example of room plugin by id. [ roomId: " + _toDisplayString(_unref(rooms).currentRoom?.roomId) + " ]", 1)
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(Json, {
            json: _unref(rooms).currentRoom?.getLiveTimelineEvents()
          }, null, 8, ["json"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})