import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-2 bg-black text-white opacity-50" }

import H3 from '@/components/elements/H3.vue';
	import Json from '@/components/elements/Json.vue';

	import { TEvent } from '@/logic/store/rooms';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'PluginEventTypeExample',
  props: {
    event: {}
  },
  setup(__props: any) {

	// Components
	

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(H3, null, {
      default: _withCtx(() => [
        _createTextVNode("Plugin event type = '" + _toDisplayString(_ctx.event.plugin?.type) + "'", 1)
      ]),
      _: 1
    }),
    _createVNode(Json, { json: _ctx.event }, null, 8, ["json"])
  ]))
}
}

})