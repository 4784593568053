import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex h-fit w-fit shrink-0 items-center justify-center" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

import { icons, sizes } from '@/assets/icons';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Icon',
  props: {
		type: {
			type: String,
			default: 'empty',
			validator(value: string) {
				return Object.keys(icons).includes(value);
			},
		},
		size: {
			type: String,
			default: 'base',
			validator(value: string) {
				return Object.keys(sizes).includes(value);
			},
		},
		asButton: {
			type: Boolean,
			default: false,
		},
		iconColor: {
			type: String,
			default: 'text-on-surface',
		},
	},
  setup(__props) {

	

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.asButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: _normalizeClass(['flex items-center justify-center', __props.iconColor])
        }, [
          (_openBlock(), _createElementBlock("svg", {
            viewBox: "0 0 24 24",
            fill: "transparent",
            stroke: "currentColor",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            class: _normalizeClass(_unref(sizes)[__props.size]),
            innerHTML: _unref(icons)[__props.type]
          }, null, 10, _hoisted_2))
        ], 2))
      : (_openBlock(), _createElementBlock("svg", {
          key: 1,
          viewBox: "0 0 24 24",
          fill: "transparent",
          stroke: "currentColor",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          class: _normalizeClass(_unref(sizes)[__props.size]),
          innerHTML: _unref(icons)[__props.type]
        }, null, 10, _hoisted_3))
  ]))
}
}

})