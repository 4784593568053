import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'Badge',
  props: {
		color: {
			type: String,
			default: 'hub',
		},
	},
  setup(__props) {

	const colorClass: { [key: string]: string } = {
		ph: 'bg-accent-primary text-on-accent-primary',
		hub: 'bg-accent-primary text-on-accent-primary',
	};

	const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([colorClass[props.color], "relative flex h-6 w-6 items-center justify-center overflow-hidden rounded-full font-bold ~text-label-small-min/label-small-max"])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})